// import App from '@/vue/App.vue';
// import { createApp } from 'vue';
// import gsap from "gsap"
// import ScrollTrigger from "gsap/ScrollTrigger"
// import ScrollToPlugin from "gsap/ScrollToPlugin"
// import Glide from '@glidejs/glide'

// gsap.registerPlugin(ScrollTrigger);

// var carousels = [];

// App main
const main = async () => {
    // Async load the Vue 3 APIs we need from the Vue ESM
    // Create our vue instance
    // const app = createApp(App);

    // Mount the app
    // const root = app.mount('#component-container');
    // Bootstrap smooth scroll
    // const scrollers = document.querySelectorAll('a[data-scroller]')
    // scrollers.forEach(scroller => {
    //     scroller.addEventListener('click', (e) => {
    //         e.preventDefault();
    //         var target = scroller.getAttribute('href');
    //         gsap.to(window, {duration: 1, scrollTo: target})
    //     })
    // })
};

// Execute async function
main().then( (root) => {
    if (document.readyState !== 'loading') {
        runOnStart();
    } else {
        document.addEventListener('DOMContentLoaded', () => {
            runOnStart();
        })
    }
});

function runOnStart() {
    // const glides = document.querySelectorAll('.glide');
    // glides.forEach(carousel => {
    //     carousels.push({
    //         elm: carousel,
    //         glide: new Glide(carousel, {
    //             type: 'carousel',
    //             focusAt: 'center',
    //             perView: 1
    //         }).mount()
    //     });
    // })

    // Activate menu toggle
    const menuToggle = document.querySelectorAll('.nav-toggle button')[0];
    const menuWrap = document.querySelectorAll('div.menu-wrap')[0];
    menuToggle.addEventListener('click', () => {
        menuToggle.classList.toggle('is-active');
        menuWrap.classList.toggle('is-active');
    })
}

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
